<template>
  <nav
    class="w-full flex items-center justify-between border-b-[1px] border-darkblue-300 gap-2 px-6 h-16 md:h-[76px]"
  >
    <div class="flex items-center md:flex-shrink-0 md:mr-6">
      <img
        class="h-8 object-contain cursor-pointer"
        src="/assets/logo.svg"
        @click="router.push('/')"
      />
    </div>
    <button
      v-if="showSignoutButton"
      class="flex flex-row justify-center w-auto bg-primary rounded-lg px-3 sm:px-4 py-1.5 font-semibold disabled:cursor-default disabled:opacity-50 bg-gradient-to-r from-primary-from to-primary-to hover:from-primary-700 hover:to-primary-600 focus:outline-none focus:ring focus:ring-primary-300 text-white"
      @click="signOut()"
    >
      <LogoutCurve class="w-0 sm:w-auto sm:mr-2" />
      {{ t("navigation.sign_out") }}
    </button>
    <div v-if="!hideMenu" class="flex flex-grow items-center w-auto h-full">
      <navigation-items
        v-if="!route.meta.publicTour"
        @nav-clicked="toggleNavigation"
        class="hidden md:flex"
      />
      <navigation-profile class="ml-auto" v-if="!route.meta.publicTour" />
      <form-button
        v-else
        class="w-fit shadow-lg py-2 px-3 md:py-4 md:px-10 ml-auto border border-white hover:border-slate-400 text-sm md:text-base"
        :command="() => router.push('/lander')"
        label="lander_from_public"
        type="button"
        textVariant="white"
        variant="transparent"
      >
      </form-button>
    </div>
  </nav>
</template>
<!---->
<script>
import { ref } from "vue";
import { useI18n } from "vue-i18n";
import { LogoutCurve } from "vue-iconsax";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import FormButton from "../form/FormButton.vue";
import NavigationItems from "./NavigationItems.vue";
import NavigationProfile from "./NavigationProfile.vue";
export default {
  components: {
    FormButton,
    NavigationItems,
    NavigationProfile,
    LogoutCurve,
  },
  props: {
    hideMenu: {
      default: false,
      required: false,
      type: Boolean,
    },
    showSignoutButton: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { t } = useI18n();

    const showNavigation = ref(false);
    const toggleNavigation = () =>
      (showNavigation.value = !showNavigation.value);
    const signOut = () => {
      if (!confirm(t("confirm.complete_signout"))) return;
      store.dispatch("authentication/signOut");
    };

    return {
      showNavigation,
      t,
      route,
      router,
      toggleNavigation,
      signOut,
    };
  },
};
</script>
