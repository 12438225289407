import { createApp, h } from "vue";
import App from "./App.vue";
import { auth } from "./firebase";
import i18n from "./i18n";
import router from "./router";
import store from "./store";
import "./styles/app.css";
import './registerServiceWorker'

let app;

auth.onAuthStateChanged(async (user) => {
  if (!app) {
    if (user) {
      await store.dispatch("authentication/initializeState", user);
    }
    app = createApp({
      render() {
        return h(App);
      },
    });

    app.use(i18n);
    app.use(router);
    app.use(store);

    app.mount("#app");
  }
});
