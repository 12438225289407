<template>
  <div
    class="bg-gradient-to-b from-darkblue-from to-darkblue-to bg-darkblue h-full overflow-y-auto"
  >
    <navigation-menu
      v-if="
        route.meta.authRequired ||
        route.meta.emptyHeader ||
        route.meta.publicTour
      "
      :hideMenu="route.meta.emptyHeader ? true : false"
      :showSignoutButton="route.meta.showSignoutButton ? true : false"
    />
    <router-view
      :class="
        route.meta.authRequired || route.meta.publicTour
          ? 'w-full lg:w-8/12 m-auto pb-[92px] md:pb-24 md:pt-4'
          : null
      "
    />
    <navigation-menu-footer v-if="route.meta.authRequired" />
    <div class="absolute top-6 right-6 flex flex-col gap-2">
      <toast
        v-for="toast in toasts"
        :key="toast.id"
        :content="toast.content"
        :id="toast.id"
        :life="toast.life"
        :severity="toast.severity"
        @destroy="destroyToast"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import NavigationMenu from "./components/navigation/NavigationMenu.vue";
import NavigationMenuFooter from "./components/navigation/NavigationMenuFooter.vue";
import Toast from "./components/toast/Toast.vue";
export default {
  components: { NavigationMenu, NavigationMenuFooter, Toast },
  setup() {
    const route = useRoute();
    const store = useStore();

    const counter = ref(0);
    const toasts = ref([]);

    const destroyToast = (id) => {
      const index = toasts.value.findIndex((toast) => toast.id == id);
      if (index != -1) {
        toasts.value.splice(index, 1);
      }
    };

    // begin toast watchers
    watch(
      () => store.state.authentication.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 3000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.user.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.availability.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );

    watch(
      () => store.state.session.status,
      (status) => {
        if (status.error) {
          toasts.value.push({
            content: status.error,
            id: counter.value,
            life: 3000,
            severity: "error",
          });
          counter.value++;
        }
        if (status.success) {
          toasts.value.push({
            content: status.success.code,
            id: counter.value,
            life: 6000,
            severity: "success",
          });
          counter.value++;
        }
      },
      { deep: true }
    );
    // end toast watchers

    return {
      destroyToast,
      route,
      toasts,
    };
  },
};
</script>

<style>
* {
  margin: 0;
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  overscroll-behavior: none;
}

html,
body,
#app {
  height: 100%;
}
</style>
