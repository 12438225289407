import { functions } from "@/firebase";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    sessions: [],
    session: {},
    mentorSessions: [],
    status: {},
    upcomingSessions: [],
    waiting: false,
    error: null,
  };
};

const state = getDefaultState();

const actions = {
  async bookSession({ commit }, data) {
    try {
      commit("wait");
      const bookSession = httpsCallable(functions, "bookSession");
      const res = await bookSession(data);
      if (!res.data.success) throw res.data.code || "unknown";
      // to payment page
      if (res.data.data.url) window.location.href = res.data.data.url;
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateSessionStatus({ commit }, data) {
    try {
      const updateSessionStatus = httpsCallable(
        functions,
        "updateSessionStatus"
      );
      const res = await updateSessionStatus(data);
      if (!res.data.success) throw res.data.code || "unknown";
    } catch (error) {
      commit("failure", error);
    }
  },
  async getUserSessions({ commit }) {
    try {
      commit("wait");
      const getUserSessions = httpsCallable(functions, "getUserSessions");
      const res = await getUserSessions();
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSessions", res.data.data);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async getMentorSessions({ commit }) {
    try {
      commit("wait");
      const getMentorSessions = httpsCallable(functions, "getMentorSessions");
      const res = await getMentorSessions();
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setMentorSessions", res.data.data);
    } catch (error) {
      console.log(error);
      commit("failure", error);
    }
  },
  async getUpcomingSessions({ commit }) {
    try {
      commit("wait");
      const getUpcomingSessions = httpsCallable(
        functions,
        "getUpcomingSessions"
      );
      const res = await getUpcomingSessions();
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setUpcomingSessions", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getSession({ commit }, id) {
    try {
      commit("wait");
      const getSession = httpsCallable(functions, "getSession");
      const res = await getSession(id);
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSession", res.data.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async cancelSession({ commit }, id) {
    try {
      commit("wait");
      const cancelSession = httpsCallable(functions, "cancelSession");
      const res = await cancelSession(id);
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSuccess", "session_canceled");
      commit("setSessionCanceled", id);
    } catch (error) {
      console.log("error", error);
      commit("failure", error);
    }
  },
};

const mutations = {
  setUpcomingSessions(state, upcomingSessions) {
    state.upcomingSessions = upcomingSessions;
    state.waiting = false;
  },
  setSession(state, session) {
    state.session = session;
    state.waiting = false;
  },
  setSessions(state, sessions) {
    state.sessions = state.sessions.concat(sessions);
    state.waiting = false;
  },
  setMentorSessions(state, sessions) {
    state.mentorSessions = state.mentorSessions.concat(sessions);
    state.waiting = false;
  },
  setQueryMonths(state, date) {
    let dateString = `${date.getFullYear()}-${date.getMonth()}`;
    if (!state.sessionQueryMonths.includes(dateString))
      state.sessionQueryMonths.push(dateString);
  },
  addSession(state, session) {
    state.sessions.push(session);
    state.waiting = false;
  },
  replaceSession(state, session) {
    const index = state.sessions.findIndex((s) => s.id == session.id);
    if (index != -1) state.sessions.splice(index, 1, session);
    state.session = session;
    state.waiting = false;
  },
  setSessionCanceled(state, sessionId) {
    if (state.session && state.session.id && state.session.id == sessionId)
      state.session.canceled = true;
    if (state.sessions && state.sessions.length) {
      const index = state.sessions.findIndex((s) => s.id == sessionId);
      if (index != -1) state.sessions[index].canceled = true;
    }
    if (state.mentorSessions && state.mentorSessions.length) {
      const index = state.mentorSessions.findIndex((s) => s.id == sessionId);
      if (index != -1) state.mentorSessions[index].canceled = true;
    }
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getSession(state) {
    return state.session;
  },
};

const session = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default session;
