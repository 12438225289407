<template>
  <button
    class="rounded-lg px-4 py-1.5 font-semibold disabled:cursor-default disabled:opacity-50"
    :class="[
      gradient
        ? `bg-gradient-to-r from-${variant}-from to-${variant}-to hover:from-${variant}-700 hover:to-${variant}-600`
        : `bg-${variant}-500 hover:bg-${variant}-600`,
      `focus:outline-none focus:ring focus:ring-${variant}-300 text-${textVariant}`,
    ]"
    :disabled="disabled"
    :type="type"
    :form="form"
    @click="command"
  >
    {{ t(`buttons.${label}`) }}
  </button>
</template>

<script>
import { useI18n } from "vue-i18n";
export default {
  props: {
    command: {
      default: () => null,
      required: false,
      type: Function,
    },
    form: {
      required: false,
      type: String,
    },
    disabled: {
      required: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    textVariant: {
      required: true,
      type: String,
      validator(value) {
        return ["black", "white"].includes(value);
      },
    },
    type: {
      default: "button",
      type: String,
    },
    variant: {
      required: true,
      type: String,
    },
    gradient: {
      default: false,
      required: false,
      type: Boolean,
    },
  },
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>
