import { auth } from "@/firebase";

export const getUserName = (userId, users) => {
  const found = users.find((user) => user.id == userId);
  return found ? found.firstName + " " + found.lastName : null;
};

export const currentUserIsMentor = async () => {
  try {
    const currentUser = auth.currentUser;
    const idTokenResult = await currentUser.getIdTokenResult();
    return idTokenResult.claims && idTokenResult.claims.mentor ? true : false;
  } catch (e) {
    return false;
  }
};
