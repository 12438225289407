<template>
  <div
    class="flex justify-between md:justify-normal w-full h-full md:gap-y-2 gap-3"
  >
    <navigation-item
      v-for="item in items"
      :key="item.index"
      :item="item"
      @click="navigateTo(item)"
    />
  </div>
</template>

<script>
import { reactive, markRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
import {
  Home2,
  SearchNormal1,
  CalendarTick,
  Calendar2,
  User,
} from "vue-iconsax";
import NavigationItem from "./NavigationItem.vue";
export default {
  components: { NavigationItem },
  setup(_, { emit }) {
    const router = useRouter();
    const route = useRoute();

    const items = reactive([
      { icon: markRaw(Home2), label: "home", path: "/" },
      {
        icon: markRaw(SearchNormal1),
        label: "search",
        path: "/search",
        alternatePaths: ["/category", "/mentor"],
      },
      {
        icon: markRaw(Calendar2),
        label: "own_schedule",
        path: "/session",
      },
      {
        icon: markRaw(CalendarTick),
        label: "schedule",
        path: "/schedule",
        mentorOnly: true,
      },
      {
        icon: markRaw(User),
        label: "profile",
        path: "/profile",
        class: "md:hidden",
      },
    ]);

    const navigateTo = (item) => {
      if (item.path == route.path) return;
      router.push(item.path);
      emit("nav-clicked");
    };

    return {
      items,
      navigateTo,
    };
  },
};
</script>
