import { auth, db, functions } from "@/firebase";
import i18n from "@/i18n";
import router from "@/router";
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    status: {},
    user: {},
    waiting: false,
  };
};

const state = getDefaultState();

const getUserData = async (user) => {
  try {
    if (!user) return null;
    const docRef = doc(db, "users", user.uid);
    const docSnap = await getDoc(docRef);
    const data = docSnap.exists() ? docSnap.data() : null;
    if (data) {
      data.id = user.uid;
      data.email = user.email;
    }
    return data;
  } catch (error) {
    return {
      error: error,
    };
  }
};

const hasToReset = (idToken) => {
  return idToken.claims.registrationIncomplete ? true : false;
};

const isAdmin = (idToken) => {
  return idToken.claims.admin;
};

const actions = {
  async activateUser({ commit, dispatch }, data) {
    try {
      commit("wait");
      const activateUser = httpsCallable(functions, "activateUser");
      const res = await activateUser(data);

      if (!res.data.success) {
        throw res.data.code || "unknown";
      }

      const user = auth.currentUser;
      const userData = res.data.data;
      if (userData.error) {
        commit("setSuccess", "activated");
        dispatch("signIn", { username: user.email, password: data.password });
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async initializeState({ commit }, user) {
    if (state.user.id) return;
    try {
      const userData = await getUserData(user);
      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setUser", userData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async signUp({ commit, dispatch }, data) {
    try {
      commit("wait");
      const registerUser = httpsCallable(functions, "registerUser");
      const res = await registerUser(data);

      if (!res.data.success) {
        throw res.data.errorInfo.code;
      }
      commit("setSuccess", "registered");
      router.push("/sign-in");
    } catch (error) {
      commit("failure", error.split("/")[1] || "unknown");
    }
  },
  async signIn({ commit, dispatch }, { username, password }) {
    try {
      commit("wait");
      const { user } = await signInWithEmailAndPassword(
        auth,
        username,
        password
      );

      const idTokenResult = await user.getIdTokenResult();
      const userData = await getUserData(user);

      if (hasToReset(idTokenResult)) {
        if (userData.error) {
          commit("failure", userData.error);
        } else {
          commit("setUser", userData);
          router.push("/complete");
        }
      } else {
        if (userData.error) {
          commit("failure", userData.error);
        } else {
          commit("setUser", userData);
          router.push("/");
        }
      }
    } catch (error) {
      commit("failure", error.code.split("/")[1] || "unknown");
    }
  },
  async signOut({ commit, dispatch }) {
    try {
      commit("wait");
      await auth.signOut();
      router.push("/sign-in");
      dispatch("clearAll", null, { root: true });
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateUser({ commit }, data) {
    try {
      const user = auth.currentUser;
      const docRef = doc(db, "users", user.uid);

      if (data.firstName) data.firstName = data.firstName.toLowerCase();
      if (data.lastName) data.lastName = data.lastName.toLowerCase();

      await updateDoc(docRef, data);

      const userData = await getUserData(user);

      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setSuccess", "profile_updated");
        commit("setUser", userData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateMentorService({ commit }, data) {
    try {
      commit("wait");
      const updateMentorService = httpsCallable(
        functions,
        "updateMentorService"
      );
      const res = await updateMentorService(data);
      if (!res.data.success) throw res.data.code || "unknown";

      const user = auth.currentUser;
      const userData = await getUserData(user);
      commit("setSuccess", "profile_updated");
      commit("setUser", userData);
    } catch (error) {
      commit("failure", error);
    }
  },
  async deleteMentorService({ commit }, categoryId) {
    try {
      commit("wait");
      const deleteMentorService = httpsCallable(
        functions,
        "deleteMentorService"
      );
      const res = await deleteMentorService(categoryId);
      if (!res.data.success) throw res.data.code || "unknown";

      const user = auth.currentUser;
      const userData = await getUserData(user);
      commit("setSuccess", "profile_updated");
      commit("setUser", userData);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateProfileAvatar({ commit }, data) {
    try {
      commit("wait");
      const user = auth.currentUser,
        updateAvatar = httpsCallable(functions, "updateAvatar");
      const updated = await updateAvatar(data);
      if (!updated.data || !updated.data.success) {
        commit("failure", "profile_image_update_failed");
        return;
      }
      const userData = await getUserData(user);
      if (userData.error) {
        commit("failure", userData.error);
      } else {
        commit("setSuccess", "profile_updated");
        commit("setUser", userData);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async changeEmail({ commit }, email) {
    try {
      commit("wait");
      const updateEmail = httpsCallable(functions, "updateEmail");
      const res = await updateEmail({ email });
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSuccess", "email_request_sent");
    } catch (error) {
      commit("failure", error);
    }
  },
  async verifyNewEmail({ commit }, token) {
    commit("wait");
    try {
      const verifyEmail = httpsCallable(functions, "verifyEmail");
      const res = await verifyEmail({ token });
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSuccess", "email_changed");
      await auth.signOut();
    } catch (error) {
      commit("failure", error);
    }
  },
  async changePassword({ commit }, password) {
    commit("wait");
    try {
      const updatePassword = httpsCallable(functions, "updatePassword");
      const res = await updatePassword({ password });
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSuccess", "password_request_sent");
      await auth.signOut();
    } catch (error) {
      commit("failure", error);
    }
  },
  async requestPasswordReset({ commit }, email) {
    commit("wait");
    try {
      await sendPasswordResetEmail(auth, email);
      commit("setSuccess", "request");
      //router.push("/reset");
    } catch (error) {
      commit("failure", error);
    }
  },
  async resetPassword({ commit }, { code, password }) {
    commit("wait");
    try {
      await confirmPasswordReset(auth, code, password);
      commit("setSuccess", "reset");
      router.push("/login");
    } catch (error) {
      commit("failure", error);
    }
  },
  async verifyNewPassword({ commit }, token) {
    commit("wait");
    try {
      const verifyPassword = httpsCallable(functions, "verifyPassword");
      const res = await verifyPassword({ token });
      if (!res.data.success) throw res.data.code || "unknown";
      commit("setSuccess", "password_changed");
    } catch (error) {
      commit("failure", error);
    }
  },
  async completeUser({ commit, dispatch }, data) {
    commit("wait");
    try {
      const activateUser = httpsCallable(functions, "activateUser");
      if (data.firstName) data.firstName = data.firstName.toLowerCase();
      if (data.lastName) data.lastName = data.lastName.toLowerCase();
      const res = await activateUser(data);
      if (!res.data.success) throw res.data.code || "unknown";
      const user = auth.currentUser;
      if (user) {
        const userData = await getUserData(user);
        if (userData.error) {
          commit("failure", userData.error);
        } else {
          commit("setSuccess", "registration_completed");
          dispatch("signOut");
        }
      }
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  setUser(state, user) {
    state.user = user;
    i18n.global.locale.value = user.language;
    localStorage.setItem("adviser-language", user.language);
    state.waiting = false;
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getUser(state) {
    return state.user;
  },
};

const authentication = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default authentication;
