import i18n from "../i18n";
const { t } = i18n.global;

export const getISODate = (date) => {
  if (!date) return;
  if (typeof date != "object") date = new Date(date);

  const year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();

  if (month < 10) month = `0${month}`;
  if (day < 10) day = `0${day}`;

  return `${year}-${month}-${day}`;
};

export const getLocaleDate = (locale, dateString) => {
  if (!dateString) return;
  return new Date(dateString).toLocaleDateString(locale, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};

export const getDayIndex = (dateString) => {
  if (!dateString) return;
  return new Date(dateString).getDay();
};

export const getMonth = (dateString) => {
  if (!dateString) return;
  return new Date(dateString).getMonth();
};

export const getLocaleMonth = (locale, dateString) => {
  if (!dateString) return;
  return new Date(dateString).toLocaleString(locale, { month: "long" });
};

export const getYear = (dateString) => {
  if (!dateString) return;
  return new Date(dateString).getFullYear();
};

export const getDateDayDifference = (dateFrom, dateTo) =>
  parseInt((dateTo - dateFrom) / (1000 * 60 * 60 * 24), 10);

export const formatDate = (dateString) => {
  if (!dateString) return;
  const today = new Date().toISOString().split("T", 1)[0];
  if (today == dateString) {
    return t("calendar.buttons.today");
  } else {
    return dateString.split("-").reverse().join(".");
  }
};
