import { auth } from "@/firebase";
import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: () => import("../views/Contact.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("../views/profile/Profile.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile/edit",
    name: "Profile edit",
    component: () => import("../views/profile/ProfileEdit.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/profile/mentor",
    name: "Profile mentor",
    component: () => import("../views/profile/ProfileMentor.vue"),
    meta: {
      authRequired: true,
      roleRequiredMentor: true,
    },
  },
  {
    path: "/schedule",
    name: "Schedule",
    component: () => import("../views/Schedule.vue"),
    meta: {
      authRequired: true,
      roleRequiredMentor: true,
    },
  },
  {
    path: "/search",
    name: "Search",
    component: () => import("../views/Search.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/session",
    name: "Sessions",
    component: () => import("../views/session/Sessions.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/session/:sessionId",
    name: "Session",
    component: () => import("../views/session/Session.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/payment/success",
    name: "MentorOrderSuccess",
    component: () => import("../views/mentor/MentorOrderSuccess.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/payment/cancel",
    name: "MentorOrderFailure",
    component: () => import("../views/mentor/MentorOrderFailure.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mentor/order/:mentorId",
    name: "MentorOrder",
    component: () => import("../views/mentor/MentorOrder.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/mentor/:mentorId",
    name: "Mentor",
    component: () => import("../views/mentor/Mentor.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/category/:categoryId",
    name: "Category",
    component: () => import("../views/Category.vue"),
    meta: {
      authRequired: true,
    },
  },
  {
    path: "/lander",
    name: "Lander",
    component: () => import("../views/Lander.vue"),
  },
  {
    path: "/info",
    name: "Info",
    component: () => import("../views/Info.vue"),
  },
  {
    path: "/terms-and-conditions",
    name: "Terms and conditions",
    component: () => import("../views/TermsAndConditions.vue"),
  },
  {
    path: "/sign-in",
    name: "Sign-in",
    component: () => import("../views/SignIn.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/sign-up",
    name: "Sign-up",
    component: () => import("../views/SignUp.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/complete",
    name: "Complete",
    component: () => import("../views/auth/Complete.vue"),
    meta: {
      emptyHeader: true,
      showSignoutButton: true,
    },
  },
  {
    path: "/verify_email/:token",
    name: "Verify email",
    component: () => import("../views/auth/Verify.vue"),
  },
  {
    path: "/verify_password/:token",
    name: "Verify password",
    component: () => import("../views/auth/Verify.vue"),
    meta: {
      emptyHeader: true,
    },
  },
  {
    path: "/home",
    name: "Home public",
    component: () => import("../views/HomePublic.vue"),
    meta: {
      publicTour: true,
    },
  },
  {
    path: "/home/mentor/:mentorId",
    name: "Mentor public",
    component: () => import("../views/mentor/Mentor.vue"),
    meta: {
      publicTour: true,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return {
      left: 0,
      top: 0,
    };
  },
});

router.beforeResolve(async (to, from, next) => {
  const currentUser = auth.currentUser;
  const idTokenResult = await currentUser?.getIdTokenResult();
  const isMentor = idTokenResult?.claims.mentor;
  const isRegistrationIncomplete = idTokenResult?.claims.registrationIncomplete;

  // redirect by logged in status
  if (to.path == "/" && !currentUser) return next("/lander");
  if (to.meta.authRequired && !currentUser) return next("/sign-in");
  if (to.meta.authRequired && isRegistrationIncomplete)
    return next("/complete");
  if (
    (to.path == "/lander" ||
      to.path == "/sign-in" ||
      to.path == "/sign-up" ||
      to.meta.publicTour) &&
    currentUser
  )
    return next({ path: "/", replace: true });

  if (to.path == "/complete" && !currentUser) return next("/lander");
  if (to.path == "/complete" && !isRegistrationIncomplete) return next("/");

  // redirect by role
  if (to.meta.roleRequiredMentor && !isMentor) return next("/");

  return next();
});

export default router;
