import { auth, db, functions } from "@/firebase";
import { getISODate } from "@/utils/dateUtils";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";

const getDefaultState = () => {
  return {
    availabilities: [],
    availabilityQueryMonths: [],
    mentorAvailabilities: [],
    mentorAvailabilityQueryMonths: [],
    availability: {},
    status: {},
    waiting: false,
    error: null,
  };
};

const state = getDefaultState();

const actions = {
  async getUserMonthsAvailabilities({ commit }, isoDate) {
    let date = new Date(isoDate),
      dateString = `${date.getFullYear()}-${date.getMonth()}`;
    if (state.availabilityQueryMonths.includes(dateString)) return;
    const userId = auth.currentUser.uid,
      y = date.getFullYear(),
      m = date.getMonth(),
      startOfRange = getISODate(new Date(y, m, 0)),
      endOfRange = getISODate(new Date(y, m + 1, 0));
    try {
      commit("wait");
      const q = query(
        collection(db, "availabilities"),
        where("userId", "==", userId),
        where("date", ">", startOfRange),
        where("date", "<=", endOfRange)
      );
      const querySnapshot = await getDocs(q);
      const availabilities = querySnapshot.docs.map((doc) => {
        const availability = doc.data();
        availability.id = doc.id;
        return availability;
      });
      commit("setAvailabilities", availabilities);
      commit("setQueryMonths", date);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getMentorMonthAvailabilities({ commit }, { mentorId, date }) {
    const dateString = `${date.getFullYear()}-${date.getMonth()}`;
    if (
      state.mentorAvailabilityQueryMonths.includes(dateString) &&
      state.mentorAvailabilities.length &&
      state.mentorAvailabilities[0].userId == mentorId
    )
      return;
    try {
      commit("wait");
      const getMentorAvailabilities = httpsCallable(
        functions,
        "getMentorAvailabilities"
      );
      const res = await getMentorAvailabilities({
        mentorId,
        date: getISODate(date),
      });
      commit("setMentorAvailabilities", res?.data?.data);
      commit("setMentorQueryMonths", date);
    } catch (error) {
      commit("failure", error);
    }
  },
  async getAvailability({ commit }, id) {
    try {
      commit("wait");
      const docRef = doc(db, "availabilities", id);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const availability = docSnap.data();
        availability.id = docSnap.id;
        commit("setAvailability", availability);
      }
    } catch (error) {
      commit("failure", error);
    }
  },
  async createAvailabilities({ commit }, data) {
    try {
      commit("wait");
      const create = httpsCallable(functions, "createAvailability");
      const res = await create(data);
      commit("setSuccess", "availabilities_created");
      commit("setAvailabilities", res?.data?.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateAvailability({ commit }, data) {
    try {
      commit("wait");
      const update = httpsCallable(functions, "updateAvailability");
      const res = await update(data);
      commit("setSuccess", "availability_updated");
      commit("replaceAvailability", res?.data?.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async updateAvailabilityBatch({ commit }, data) {
    try {
      commit("wait");
      const update = httpsCallable(functions, "updateAvailabilityBatch");
      const res = await update(data);
      commit("setSuccess", "availabilities_updated");
      commit("replaceAvailabilities", res?.data?.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async removeAvailability({ commit }, data) {
    try {
      commit("wait");
      const remove = httpsCallable(functions, "removeAvailability");
      const res = await remove(data);
      commit("setSuccess", "availability_removed");
      commit("removeAvailability", res?.data?.data);
    } catch (error) {
      commit("failure", error);
    }
  },
  async removeAvailabilityBatch({ commit }, data) {
    try {
      commit("wait");
      const remove = httpsCallable(functions, "removeAvailabilityBatch");
      const res = await remove(data);
      commit("setSuccess", "availabilities_removed");
      commit("removeAvailabilities", res?.data?.data);
    } catch (error) {
      commit("failure", error);
    }
  },
};

const mutations = {
  setAvailability(state, availability) {
    state.availability = availability;
    state.waiting = false;
  },
  setAvailabilities(state, availabilities) {
    state.availabilities = state.availabilities.concat(availabilities);
    state.waiting = false;
  },
  setMentorAvailabilities(state, availabilities) {
    if (
      state.mentorAvailabilities.length &&
      availabilities.length &&
      state.mentorAvailabilities[0].userId == availabilities.userId
    ) {
      state.mentorAvailabilities =
        state.mentorAvailabilities.concat(availabilities);
    } else {
      state.mentorAvailabilities = availabilities;
    }
    state.waiting = false;
  },
  setQueryMonths(state, date) {
    let dateString = `${date.getFullYear()}-${date.getMonth()}`;
    if (!state.availabilityQueryMonths.includes(dateString))
      state.availabilityQueryMonths.push(dateString);
  },
  setMentorQueryMonths(state, date) {
    let dateString = `${date.getFullYear()}-${date.getMonth()}`;
    if (!state.mentorAvailabilityQueryMonths.includes(dateString))
      state.mentorAvailabilityQueryMonths.push(dateString);
  },
  addAvailability(state, availability) {
    state.availabilities.push(availability);
    state.waiting = false;
  },
  replaceAvailability(state, availability) {
    if (state.availabilities.length) {
      const index = state.availabilities.findIndex(
        (a) => a.id == availability.id
      );
      if (index != -1) state.availabilities.splice(index, 1, availability);
    } else {
      state.availabilities.push(availability);
    }
    state.availability = availability;
    state.waiting = false;
  },
  replaceAvailabilities(state, availabilities) {
    if (state.availabilities.length) {
      availabilities.forEach((av) => {
        const index = state.availabilities.findIndex((a) => a.id == av.id);
        if (index != -1) state.availabilities.splice(index, 1, av);
      });
    } else {
      state.availabilities = state.availabilities.concat(availabilities);
    }
    state.waiting = false;
  },
  removeAvailability(state, availabilityId) {
    if (state.availabilities.length) {
      const index = state.availabilities.findIndex(
        (a) => a.id == availabilityId
      );
      if (index != -1) state.availabilities.splice(index, 1);
    }
    state.availability = null;
    state.waiting = false;
  },
  removeAvailabilities(state, availabilityIds) {
    if (state.availabilities.length) {
      availabilityIds.forEach((id) => {
        const index = state.availabilities.findIndex((a) => a.id == id);
        if (index != -1) state.availabilities.splice(index, 1);
      });
    }
    state.waiting = false;
  },
  failure(state, error) {
    state.status.error = error;
    setTimeout(() => {
      state.status = {};
    }, 100);
    state.waiting = false;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
  setSuccess(state, code) {
    state.status.success = { code };
    setTimeout(() => {
      state.status = {};
    }, 100);
  },
  wait(state) {
    state.waiting = true;
  },
};

const getters = {
  getAvailability(state) {
    return state.availability;
  },
};

const availability = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state,
};

export default availability;
